import { isAxiosError } from 'axios';
import { CHECKOUT_ERROR_BEHAVIOURS } from 'core/constants/checkout.constants';
import { CLIENT_OFFLINE_NETWORK_ERROR_CODE, TIMEOUT_NETWORK_ERROR_CODE } from 'core/constants/error-http.constants';
import { IServiceError } from 'core/model/interfaces/error.interface';
import { IOrderSuccess } from 'core/model/interfaces/order.interface';
import { apiService } from 'core/services';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { handleServiceException } from 'shared/utils/errors.utils';
import gtmEvents from 'shared/utils/gtm-events';
import { checkoutObservability } from 'shared/utils/observability/checkout.observability';
import { visitorInstance } from 'shared/utils/visitor-providers.utils';
import { getCustomerIdFromCheckout } from '../checkout-state.utils';
import { checkoutInitialState, SLICE_NAMESPACE } from '../constants';
import { ICheckoutState } from '../types';

export const purchaseOrderAction = createAppAsyncThunk<IOrderSuccess, void>(
  `${SLICE_NAMESPACE}/purchaseOrder`,
  async (_, { getState, rejectWithValue }) => {
    const { checkout, auth } = getState();
    const customerId = getCustomerIdFromCheckout(checkout);
    const { shipping, paymentMethod, detail, agreements, address } = checkout;
    const { customer } = auth;
    const { id: fingerprint } = await visitorInstance.getInformation();

    try {
      const response = await apiService.orders.createOrder(
        {
          customer,
          order: {
            detail,
            paymentMethod,
            shipping,
            agreements,
            address,
          },
          fingerprint,
        },
        customerId ?? 0,
      );

      gtmEvents.triggerPurchaseEvent(response, fingerprint);
      checkoutObservability.savePurchaseSuccess(response);
      return response;
    } catch (e) {
      const hasError = isAxiosError<IServiceError>(e);
      const defaultErrorMessage = 'Ha ocurrido un error al procesar tu orden.';
      const error = hasError ? e.response?.data : ({ error: { message: defaultErrorMessage } } as IServiceError);

      checkoutObservability.savePurchaseIntentError({
        details: checkout.detail,
        customerSource: auth.customer.source,
        error,
      });

      if (hasError) {
        if (e?.code === CLIENT_OFFLINE_NETWORK_ERROR_CODE || e?.code === TIMEOUT_NETWORK_ERROR_CODE) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw rejectWithValue(CHECKOUT_ERROR_BEHAVIOURS.reviewMyOrdersBecauseOfDisconnectionError);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw rejectWithValue(handleServiceException(e, defaultErrorMessage));
    }
  },
);

export const purchaseOrderReducer: TSliceExtraReducer<ICheckoutState> = builder => {
  builder
    .addCase(purchaseOrderAction.pending, state => {
      state.status.submitting = true;
      state.status.completed = false;
      state.error.createOrder = null;
    })
    .addCase(purchaseOrderAction.fulfilled, (state, { payload }) => {
      const { shipping, paymentMethod, detail } = checkoutInitialState;
      state.status.submitting = false;
      state.status.completed = true;
      state.orderSuccessDetail = payload;
      state.shipping.idle = true;
      state.shipping.deliveryDates = shipping.deliveryDates;
      state.paymentMethod = paymentMethod;
      state.detail = detail;
      state.error.createOrder = null;
    })
    .addCase(purchaseOrderAction.rejected, (state, { payload }) => {
      state.status.submitting = false;
      state.status.completed = false;
      if (payload) {
        state.error.createOrder = payload;
      }
    });

  return builder;
};
